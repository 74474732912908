import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apartmentApi, feedApi } from "helpers";

const name = "apartment";
const initialState = { all: [], client: { apartments: [], client: {} } };
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

export const apartmentActions = { ...slice.actions, ...extraActions };
export const apartmentReducer = slice.reducer;

function createReducers() {
  return {
    replaceApartments
  };
  function replaceApartments(state, { payload }) {

    if (payload.client === undefined) {
      state.all.splice(payload.hoverIndex, 0, state.all.splice(payload.dragIndex, 1)[0]);
    } else {
      state.client.apartments.splice(payload.hoverIndex, 0, state.client.apartments.splice(payload.dragIndex, 1)[0]);
    }
  }
}

function createExtraActions() {
  return {
    create: create(),
    all: all(),
    clientAll: clientAll(),
    deleteFromBoard: deleteFromBoard(),
    copyToBoard: copyToBoard(),
    approveFeed: approveFeed(),
    updateFeeds: updateFeeds(),
  };

  function create() {
    return createAsyncThunk(
      `${name}/create`,
      async (data) => await apartmentApi.create(data)
    );
  }

  function all() {
    return createAsyncThunk(
      `${name}/all`,
      async () => await apartmentApi.all()
    );
  }

  function clientAll() {
    return createAsyncThunk(
      `${name}/clientAll`,
      async (clientId) => await apartmentApi.clientAll(clientId)
    );
  }

  function deleteFromBoard() {
    return createAsyncThunk(
      `${name}/deleteFromBoard`,
      async (data) => await apartmentApi.deleteFromBoard(data.apartments, data.board, data.client?.id)
    )
  }

  function copyToBoard() {
    return createAsyncThunk(
      `${name}/copyToBoard`,
      async (data) => await apartmentApi.copyToBoard(data)
    )
  }

  function approveFeed() {
    return createAsyncThunk(
      `${name}/approveFeed`,
      async (data) => await feedApi.approve(data)
    )
  }

  function updateFeeds() {
    return createAsyncThunk(
      `${name}/updateFeeds`,
      async (data) => await feedApi.update(data)
    )
  }
}

function createExtraReducers() {
  return {
    ...all(),
    ...clientAll(),
    ...deleteFromBorder(),
    ...copyToBoard(),
    ...approveFeed(),
    ...updateFeeds(),
  };

  function all() {
    const { fulfilled } = extraActions.all;
    return {
      [fulfilled]: (state, action) => {
        localStorage.setItem('apartmentsTotal', action.payload.result.length);
        state.all = action.payload.result;
      },
    };
  }

  function clientAll() {
    const { pending, fulfilled } = extraActions.clientAll;
    return {
      [pending]: (state) => {
        state.client = { apartments: [], client: {} };
      },
      [fulfilled]: (state, action) => {
        state.client = action.payload.result;
      },
    };
  }

  function deleteFromBorder() {
    const { fulfilled } = extraActions.deleteFromBoard;
    return {
      [fulfilled]: (state, action) => {
        const { apartments, board, client } = action.meta.arg;
        if (client) {
          state.client.apartments.map(apartment => {
            if (apartments.includes(apartment.id)) {
              apartment.apartmentBoards = apartment.apartmentBoards.filter(apartmentBoard => {
                return apartmentBoard.type !== board;
              })
            }

            return apartment;
          });
        } else {
          state.all.map(apartment => {
            if (apartments.includes(apartment.id)) {
              apartment.apartmentBoards = apartment.apartmentBoards.filter(apartmentBoard => {
                if (apartmentBoard.type === board) {
                  if (apartmentBoard.status === 1) {
                    apartmentBoard.status = 0
                  } else {
                    return false;
                  }
                }

                return true;
              })
            }

            return apartment;
          })
        }
      }
    };
  }

  function copyToBoard() {
    const { fulfilled } = extraActions.copyToBoard;
    return {
      [fulfilled]: (state, action) => {
        const { apartmentId, client } = action.meta.arg;
        if (client) {
          state.client.apartments.map(apartment => {
            if (apartment.id === apartmentId) {
              apartment.apartmentBoards.push(action.payload.result)
            }

            return apartment;
          })
        } else {
          state.all.map(apartment => {
            if (apartment.id === apartmentId) {
              apartment.apartmentBoards.push(action.payload.result)
            }

            return apartment;
          })
        }
      },
    };
  }

  function approveFeed() {
    const { fulfilled } = extraActions.approveFeed;
    return {
      [fulfilled]: (state, action) => {
        const { apartments } = action.meta.arg;
        state.all.map(apartment => {
          if (apartments.includes(apartment.id)) {
            apartment.feeds = apartment.feeds.map(item => {
              item.approved = true;

              return item;
            })
          }

          return apartment;
        })
      },
    };
  }

  function updateFeeds() {
    const { fulfilled } = extraActions.updateFeeds;

    return {
      [fulfilled]: (state, action) => {
        const { apartments, feeds } = action.meta.arg;
        state.all.map(apartment => {
          if (apartments.includes(apartment.id)) {
            apartment.feeds = apartment.feeds.map(item => {
              feeds.forEach(feed => {
                if (feed.portal === item.portal) {
                  item.active = feed.active;
                }
              })

              return item;
            })
          }

          return apartment;
        })
      },
    };
  }
}
