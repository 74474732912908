import { InputAdornment, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useMask } from '@react-input/mask';

export const InputPhoneMask = ({autoFocus, disabled, endAdornment, field, formik, label, multiline, required, rows }) => {

    const placeholder = '+38(___)___-__-__';

    const inputRef = useMask({
        mask: "+38(___)___-__-__",
        replacement: { _: /\d/ },
        placeholder: placeholder
    });

    const [phoneNumber, setPhoneNumber] = useState(formik.values[field])

    return <>
        <Typography className={required && "label"} variant="h4" mt={{ xs: 1.5, md: 3.75 }}>
            {label}
        </Typography>

        <TextField
            inputRef={inputRef}
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            error={formik.errors[field] && formik.touched[field]}
            fullWidth
            helperText={formik.touched[field] && formik.errors[field]}
            hiddenLabel
            margin="dense"
            multiline={multiline}
            name={field}
            rows={rows}
            type="text"
            variant="filled"
            InputProps={{
                endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
                sx: multiline && { padding: "0" }
                }}
            value={phoneNumber}                  
            // value={formik.values[field]}
            onChange={(e) => {
                setPhoneNumber(e.target.value)
                formik.setFieldError(e.target.name, undefined);
                formik.handleChange(e)
            }}
        />
    </>
}
