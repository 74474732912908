import { Box, Skeleton, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from "@mui/material";
import { useRef, useEffect, useMemo, useState } from "react";
import { Checkbox } from "components";
import { SelectableTableHeader } from "./SelectableTableHeader";

export function SelectableTable({handleRowClick, headCells, loading, isSelectable, rows, rowsPerPage = 10, totalCountRow, selected, setSelected, currentPreviewRow, setCurrentPreviewRow, size = "medium"}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(headCells.length ? headCells[0].id : 'id');
  const [page, setPage] = useState(0);

  const [indexElement, setIndexElement] = useState(0)

  const tableRef = useRef(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (!isSelectable) {
      return;
    }
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    if (rows[indexElement] !== undefined && window.location.pathname !== '/crm/reports') {
      setCurrentPreviewRow(rows[indexElement].id)
    }

  }, [rows])


  const isElementVisible = () => {

    const container = tableRef.current;

    const element = container.querySelector(
      `[data-row="${indexElement}"]`
    );

    if (!element || !container) {
      return false;
    }

    const elementRect = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    if ( (elementRect.bottom + elementRect.height) >= containerRect.bottom) {
      container.scrollTop = container.scrollTop + elementRect.height
    }

    if ( (elementRect.top - (elementRect.height * 2)) <= containerRect.top) {
      container.scrollTop = container.scrollTop - elementRect.height
    }
  }

  const handleKeyDown = (event) => {

    // console.log(document.activeElement)

    if (document.activeElement.tagName === "LI") {
      document.activeElement.blur();
      // return;
    }

    if (document.activeElement.tagName === "INPUT") {
      return;
    }

    if (event.code === "Space") {
      event.preventDefault();

      handleClick(event, rows[indexElement].id)
      if (rows.length !== (indexElement + 1)) {
        handleRowClick(rows[indexElement+1].id);
        setIndexElement(indexElement+1)
        setCurrentPreviewRow(rows[indexElement+1].id);
      }
      isElementVisible();
    }

    if (event.code === "ArrowDown") {
      event.preventDefault();

      if ((rows.length - 1) !== indexElement) {

        setIndexElement(indexElement+1)
        handleRowClick(rows[indexElement+1].id);
        setCurrentPreviewRow(rows[indexElement+1].id);
      }
      isElementVisible();
    }

    if (event.code === "ArrowUp") {
      event.preventDefault();

      if (indexElement !== 0) {
        setIndexElement(indexElement-1)
        handleRowClick(rows[indexElement-1].id);
        setCurrentPreviewRow(rows[indexElement-1].id);
      }
      isElementVisible();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, [currentPreviewRow])

  const handleClick = (event, name) => {
    if (!isSelectable) {
      return
    }
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // const visibleRows = useMemo(
  //   () =>
  //     stableSort(rows, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage,
  //     ),
  //   [order, orderBy, page, rowsPerPage, rows],
  // );

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalCountRow) : 0;
  const visibleHeadCells = useMemo(() => headCells.filter(cell => !cell.hasOwnProperty('visible') || cell.visible),
    [headCells]);

  return (
    <Box sx={{ width: '100%', overflowX: 'auto', height: "98%"}}>
      <TableContainer           ref={tableRef} sx={{ maxHeight: "98%",
          flexWrap: "nowrap",
          overflowX: "scroll",
          height: "100%",
          '&::-webkit-scrollbar': {
            width: '12px',
            ['-webkit-appearance']: 'none'
          },
          '&::-webkit-scrollbar-track': {
            background: "rgba(137, 137, 137, 0.20)",
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#59C8FA', // #59C8FA;
            borderRadius: '6px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#1C77FF', // #1C77FF
          },
          scrollbarColor: `#59C8FA rgba(137, 137, 137, 0.20)`,  // #59C8FA;
          scrollbarWidth: 'thin',
          '& *': {
            scrollbarWidth: 'auto',
            scrollbarColor: 'auto',
            '&::-webkit-scrollbar': {
              width: 'auto',
              background: 'auto',
            },
            '&::-webkit-scrollbar-track': {
              background: 'auto',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'auto',
              border: 'none',
            },
          },
       }}>
        <Table
          size={size}
          stickyHeader
        >
          <SelectableTableHeader
            headCells={visibleHeadCells}
            isSelectable={isSelectable}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={totalCountRow}
            indexElement={indexElement}
          />
          <TableBody>
            {loading ?
              [...Array(5)].map((_, index) => <TableRow key={index}>
                  {visibleHeadCells.map((_, index) => <TableCell key={index}><Skeleton /></TableCell>)}
                </TableRow>
              )
              : rows.map((row, index) => {
                const isItemSelected = isSelectable && isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    data-row={index}
                    hover={ currentPreviewRow === row.id ? false : true  }
                    onClick={(event) => {
                      if (handleRowClick) {
                        setIndexElement(index)
                        setCurrentPreviewRow(rows[index].id);
                        handleRowClick(rows[index].id);
                      } else {
                        handleClick(event, rows[index].id)
                      }
                    }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer'}}
                  >
                    {isSelectable && <TableCell
                    sx={{

                      borderTopWidth: '1px',
                      borderTopStyle: 'solid',
                      borderTopColor: index === indexElement ? '#59C8FA' : (theme) => theme.palette.background.white,

                      borderLeftWidth: '1px',
                      borderLeftStyle: 'solid',
                      borderLeftColor: index === indexElement ? '#59C8FA' : (theme) => theme.palette.background.white,

                      borderBottomWidth: '1px',
                      borderBottomStyle: 'solid',
                      borderBottomColor: index === indexElement ? '#59C8FA' : (indexElement - 1 === index) ? (theme) => theme.palette.background.white : null,

                      borderTopLeftRadius: index === indexElement ? '4px' : '0px',
                      borderBottomLeftRadius: index === indexElement ? '4px' : '0px',
                    }}
                    padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleClick(event, row.id)
                      }}
                    />
                  </TableCell>
                  }

                  {visibleHeadCells.map((headCell, key) => (
                    <TableCell
                      key={key}
                      align={headCell.numeric ? "center" : "left"}
                      sx={{
                        whiteSpace: 'nowrap',

                        borderTopWidth: '1px',
                        borderTopStyle: 'solid',
                        borderTopColor: index === indexElement ? '#59C8FA' : (theme) => theme.palette.background.white,

                        borderRight: index === indexElement && (visibleHeadCells.length - 1) === key ? '1px solid #59C8FA' : null,

                        borderBottomWidth: '1px',
                        borderBottomStyle: 'solid',
                        borderBottomColor: index === indexElement ? '#59C8FA' : (indexElement - 1 === index) ? (theme) => theme.palette.background.white : null,

                        borderTopRightRadius: index === indexElement && (visibleHeadCells.length - 1) === key ? '4px' : '0px',
                        borderBottomRightRadius: index === indexElement && (visibleHeadCells.length - 1) === key ? '4px' : '0px',
                      }}
                    >
                      {row[headCell.id]}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        component="div"
        count={totalCountRow}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[]}
      /> */}
    </Box>
  );
}

function descendingComparator(a, b, orderBy) {
  const valueA = a[orderBy];
  const valueB = b[orderBy];

  if (typeof valueA === 'string' && typeof valueB === 'string') {
    // Handle numeric values
    const numA = parseFloat(valueA.replace(/[^0-9.-]+/g, ''));
    const numB = parseFloat(valueB.replace(/[^0-9.-]+/g, ''));
    if (!isNaN(numA) && !isNaN(numB)) {
      return numB - numA;
    }
  }

  // Handle non-numeric values or mixed types
  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}