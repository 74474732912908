import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

export const ScrolledGrid = styled(Grid)(({ theme }) => ({
  flexWrap: "nowrap",
  overflowX: "scroll",
  height: "calc(100% - 20px)",
  '&::-webkit-scrollbar': {
    width: '12px',
    ['-webkit-appearance']: 'none'
  },
  '&::-webkit-scrollbar-track': {
    background: "rgba(137, 137, 137, 0.20)",
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main, // #59C8FA;
    borderRadius: '6px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: theme.palette.primary.dark, // #1C77FF
  },
  scrollbarColor: `${theme.palette.primary.main} rgba(137, 137, 137, 0.20)`,  // #59C8FA;
  scrollbarWidth: 'thin',
  '& *': {
    scrollbarWidth: 'auto',
    scrollbarColor: 'auto',
    '&::-webkit-scrollbar': {
      width: 'auto',
      background: 'auto',
    },
    '&::-webkit-scrollbar-track': {
      background: 'auto',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'auto',
      border: 'none',
    },
  },
}));